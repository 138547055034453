<template>
	<el-dialog v-dialogDrag title="选择支行" :visible.sync="showModel" width="60%" :before-close="handleClose" v-if="showModel"
	 :close-on-click-modal="false" :close-on-press-escape="false">
		<el-row :gutter="20">
			<el-col :span="6">
				<div class="tree-box">
					<div class="flex-row-center-center tree-title">行政区域</div>
					<div class="tree-box1">
						<el-tree v-loading='treeLoading' ref="tree" node-key="provid" class="" :default-expand-all='true' :props="props"
						 :data="treeData" @node-click="handleNodeClick" :default-expanded-keys="expandedKeys">
						</el-tree>
					</div>
				</div>
			</el-col>
			<el-col :span="18">
				<el-row :gutter="20" style="margin-bottom: 10px;">
					<el-col :span="20">
						<el-input v-model="searchText" placeholder="请输入支行名称模糊查询" clearable />
					</el-col>
					<el-col :span="4">
						<el-button type="primary" @click='getTableData(1)'>查询</el-button>
					</el-col>
				</el-row>
				<el-table :data="tableData" :height="tableHeight" border @selection-change="changeCheckBoxRow" ref="multipleTable"
				 v-loading='tableLoading' stripe :highlight-current-row="true" :header-cell-style="{ 'text-align': 'center', background: '#F7F9FE' }">
					<el-table-column type="index" fixed width="50" align="center" label="序号" />
					<el-table-column type="selection" fixed width="50" align="center" />
					<el-table-column v-for="(item, index) in tableLabel" :key="index" :prop="item.prop" :width="item.width" :label="item.label"
					 :fixed="item.fixed" show-overflow-tooltip :align="item.align" />
				</el-table>
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
				 :page-sizes="[100, 200, 300, 500]" :page-size="pageSize" layout="total, sizes, prev, pager, next" :total="total">
				</el-pagination>
			</el-col>
		</el-row>
		<span slot="footer" class="dialog-footer">
			<el-button @click="handleClose">取 消</el-button>
			<el-button type="primary" @click="Submit">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
	export default {
		props: {
			showModel: {
				type: Boolean,
				default: false
			},
			id: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				searchText: '',
				tableHeight: '435px',
				treeLoading: false,
				tableLoading: false,
				listPositionChos: [],
				tableData: [],
				total: 0,
				pageSize: 100,
				currentPage: 1,
				tableLabel: [{
					label: '支行名称',
					width: 'auto',
					prop: 'fkhmC1',
					align: 'left',
				}],
				props: {
					label: 'provname',
					children: 'children',
					isLeaf: 'leaf'
				},
				treeData: [],
				expandedKeys: [],
				citycode: ''
			};
		},
		mounted() {
			// this.queryTableList();
			this.getTreeData();
		},
		methods: {
			handleSizeChange(val) {
				this.pageSize = val;
				this.getTableData();
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.getTableData();
			},
			getTreeData() {
				this.ApiRequestPostNOMess("/api/mall/ebbase/bank-provice/get-list", {}).then(
					(result) => {
						console.log(result, "树数据");
						result.obj.items.forEach(item => {
							item.children = [];
						})
						this.treeData = result.obj.items;
					},
					(rej) => {}
				);
			},
			//表格多选事件
			changeCheckBoxRow(data) {
				this.listPositionChos = data;
			},
			//树点击事件
			handleNodeClick(node, data) {
				console.log(node, data);
				this.getTreeC(node.provid);
				if (node.citycode) {
					this.citycode = node.citycode;
					this.getTableData();
				}
			},
			getTreeC(e) {
				this.treeLoading = true;
				this.ApiRequestPostNOMess("/api/mall/ebbase/bank-city/get-list", {
					provid: e
				}).then(
					(result) => {
						console.log(result, "树数zzzz据");
						result.obj.items.forEach(item => {
							item.provname = item.cityname
							item.provid = item.cityid
						})
						this.treeData.forEach(item => {
							if (item.provid == e) {
								item.children = result.obj.items;
							}
						})
						this.treeLoading = false;
					},
					(rej) => {
						this.treeLoading = false;
					}
				);
			},
			getTableData(e) {
				if (this.citycode == '' || this.citycode == undefined) {
					this.$message.info('请先选择行政区域后查询')
					return
				}
				if (e == 1) {
					this.currentPage = 1;
				}
				this.tableLoading = true;
				this.ApiRequestPostNOMess("/api/mall/ebbase/bank-type-detail/get-list", {
					youzbm: this.citycode,
					fBankTypeCode: this.id,
					filter: this.searchText,
					maxResultCount: this.pageSize,
					skipCount: (this.currentPage - 1) * this.pageSize
				}).then(
					(result) => {
						console.log(result, "aaa");
						this.tableData = result.obj.items;
						this.tableLoading = false;
						this.total = result.obj.totalCount
					},
					(rej) => {
						this.tableLoading = false;
					}
				);
			},
			// 弹窗关闭事件
			handleClose() {
				let data = {
					isChange: false,
				}
				this.$emit('change', !this.showModel, data);
			},
			//确认选择
			Submit() {
				console.log();
				if (this.listPositionChos.length > 1) {
					this.$message.info('不支持批量选择')
					return
				}
				let data = {
					isChange: true,
					data: this.listPositionChos
				}
				this.$emit('change', !this.showModel, data);
			},
		}
	};
</script>

<style lang="scss" scoped>
	.tree-box {
		border: 1px solid #F2F2F2;
		height: 472px;
	}

	.tree-box1 {
		height: 442px;
		overflow: auto;
	}

	::v-deep .el-input__inner {
		width: 100% !important;
	}

	::v-deep .el-button {
		padding-top: 7px;
		padding-bottom: 7px;
	}

	.tree-title {
		height: 30px;
		border-bottom: 1px solid #F2F2F2;
	}
</style>
